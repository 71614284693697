import React, { useRef, useState } from 'react'
import "./Waitlist.css";
import JoinPopUp from '../JoinPopUp/JoinPopUp';


const Waitlist = () => {

  const [showModal, setShowModal] = useState(false);
  const email = useRef("");
  const form = useRef("");

  function handleSubmit() {
    setShowModal(true);
    
    const XHR = new XMLHttpRequest();
    const FD = new FormData(form.current);

    XHR.open("POST", "https://motherhome.io/signup/");

    XHR.send(FD);

    return false;
  };

  const closeModal = () => {
    setShowModal(false);

    return false;
  };


  return (
    <div className="waitlist">
    <div className="wrapper">
        <div className="cta">
            <h2>Get updates from mother home</h2>
        </div>

        <form ref={form} onSubmit={(e) => {e.preventDefault();}} className="FormGroup" action="" method="POST">
          <input ref={email} className="EmailInput" name="email" type="email" placeholder="plants@example.com"/>
          <button name="join-button" onClick={() => handleSubmit()}>JOIN WAITLIST</button>
        </form>
        <p className="disclaimer">By signing up, you agree to be sent occasional emails. You may unsubscribe at any time.</p>
        {showModal && <JoinPopUp onClose={closeModal} />} 
      </div>  
      </div>    
  )
}


export default Waitlist

