import React from 'react'
import "./Footer.css";
import footlogo from "../../assets/LOGO_TEXT_MOTHER.svg"
import { FaFacebook } from "react-icons/fa"
import { FaInstagram } from "react-icons/fa"
import { FaYoutube } from "react-icons/fa"
import { IoPhonePortrait } from "react-icons/io5"
import { RiMailSendFill } from "react-icons/ri"




const Footer = () => {
  return (
    <footer>
      <div className="wrapper">

          <div className="footlogo-container">

            <div className="footerlogo">
            <a onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
              <img src={footlogo} alt="Logo" className="mothersvg"/>
            </a>
            </div>
          </div>

          <div className="follow-links">
            <div className="icon-footer">
              <a href="mailto:motherhometech@gmail.com?subject=Mother%20RoPot%20Inquiry&body=Dear%20Motherhome," target="_self">
                <RiMailSendFill size="39px" color="#fff" title="Follow Git Hub Link" />
              </a>
              <a href="https://www.facebook.com/motherhome.io" target="_self">
                <FaFacebook size="40px" color="#fff" title="Follow Git Hub Link" />
              </a>
              <a href="https://www.youtube.com/@motherhomeio" target="_self">
                <FaYoutube size="47px" color="#fff" title="Follow Youtube Link"/>
              </a>
              <a href="https://www.instagram.com/motherhome.io" target="_self">
                <FaInstagram size="40px" color="#fff" title="Follow Git Hub Link"/>
              </a>
              <a href="tel:+1234567890" target="_self">
                <IoPhonePortrait size="43px" color="#fff" title="Follow Git Hub Link" />
              </a>
            </div>
          </div>
   
          <div className="cta">
              <p className="headline">Mother Home Technologies Inc.</p>
          </div>
      </div>
    </footer>
  )
}

export default Footer