import "./Info.css";

const Info = () => {
  return (
    <div className="info">
    <div className="info-wrapper">
        <div className="info-image"/>
        <div className="info-content">
            <h2>Save your plants, save time</h2>
            <p>Mother Home aims to incorporate nature into your indoor spaces by using revolutionary and elegant smart home technology. 
                We want to make having live plants in your home, office, or rental properties as easy as possible, and encourage people to take advantage of all the benefits of plant ownership.
                <br /><br />Starting with the RoPot, a fully-autonomous plant pot that takes the time dedication and guesswork out of owning beautiful house plants.</p>
        </div>
    </div>
    </div>    
  )
}


export default Info

