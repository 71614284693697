import React from 'react'
import { Header, Waitlist, Info, Footer } from './components'



const App = () => {
  return (
    <>
      <Header /> 
      <Info />
      <Waitlist/>
      <Footer/>
    </>
  )
}


export default App
