import React from 'react';
import './Header.css';
import logo from '../../assets/LOGO_TEXT_MOTHER.svg';
import LowBit1080 from '../../assets/RoPot_LowBit_1080_Web_UPDATED.mp4';
import LowBit720 from '../../assets/RoPot_LowBit_Web_720_Water.mp4';


// Define an array of video sources
const videoSources = [

  { src: LowBit1080, type: 'video/mp4' },
  { src: LowBit720, type: 'video/mp4' },
  
];

const Header = () => {
  return (
    <header>
      <div className="logo-container">
        <a href="" target="_top">
          <img src={logo} alt="Logo" className="logo" />
        </a>
      </div>

      <div className="media-container">
        
        <div className="video-container">
          {/* Try all video types decending in size and quality */}
          {videoSources.map((videoSource, index) => (
            <video key={index} src={videoSource.src} 
              autoPlay
              loop
              muted
              preload="auto"
              playsInline
             />
          ))}
        </div>


        <div className="cta">
          <h1>YOUR PLANT ROBOT</h1>
        </div>
      </div>
    </header>
  );
};

export default Header;
